import React from 'react'
import '../styles/FeeStructure.scss'
import { Helmet } from 'react-helmet-async'
export default function FeeStructure() {
    return (

        <>
            <Helmet>
                <title>Fee Structure - Prabhat Tara Vidya Niketan Goraul - CBSE Affiliated School</title>
                <meta name="title" content="Fee Structure - Prabhat Tara Vidya Niketan Goraul - CBSE Affiliated School" />
                <meta name="description" content="Comprehensive fee structure for Prabhat Tara Vidya Niketan Goraul, including admission fees, tuition fees, examination fees, and other charges for all classes from LKG to 10+2." />

                <meta name="keywords" content="Prabhat Tara Vidya Niketan fees, CBSE school fees Goraul, school fee structure, admission fees, tuition fees, examination fees, transportation charges, uniform costs, Vaishali school fees" />

                <link rel="canonical" href="https://prabhattaravidyaniketan.in/fee-structure" />

                <meta name="robots" content="index, follow" />
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="language" content="English" />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://prabhattaravidyaniketan.in/fee-structure" />
                <meta property="og:title" content="Fee Structure - Prabhat Tara Vidya Niketan Goraul - CBSE Affiliated School" />
                <meta property="og:description" content="Detailed fee structure for Prabhat Tara Vidya Niketan Goraul, including class-wise fees, transportation charges, and uniform costs for the current academic year." />
                <meta property="og:image" content="https://prabhattaravidyaniketan.in/images/fee-structure.jpg" />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://prabhattaravidyaniketan.in/fee-structure" />
                <meta property="twitter:title" content="Fee Structure - Prabhat Tara Vidya Niketan Goraul - CBSE Affiliated School" />
                <meta property="twitter:description" content="Explore the comprehensive fee structure of Prabhat Tara Vidya Niketan Goraul, covering all charges from admission to examination fees for classes LKG to 10+2." />
                <meta property="twitter:image" content="https://prabhattaravidyaniketan.in/images/fee-structure.jpg" />
            </Helmet>
            <div className="fee-structure">

                <section>
                    <h2>Class-wise Fee Structure</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Fee Type</th>
                                <th>LKG & UKG</th>
                                <th>Class 1-3</th>
                                <th>Class 4-8</th>
                                <th>Class 9</th>
                                <th>Class 10</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td data-label="Fee Type">Admission & Registration</td>
                                <td data-label="LKG & UKG">₹1,000</td>
                                <td data-label="Class 1-3">₹1,000</td>
                                <td data-label="Class 4-8">₹1,000</td>
                                <td data-label="Class 9">₹6,500</td>
                                <td data-label="Class 10">₹7,500</td>
                            </tr>
                            <tr>
                                <td data-label="Fee Type">Monthly Tuition</td>
                                <td data-label="LKG & UKG">₹400</td>
                                <td data-label="Class 1-3">₹400</td>
                                <td data-label="Class 4-8">₹500</td>
                                <td data-label="Class 9">₹1,000</td>
                                <td data-label="Class 10">₹1,000</td>
                            </tr>
                            <tr>
                                <td data-label="Fee Type">Half-yearly Exam</td>
                                <td data-label="LKG & UKG">₹150</td>
                                <td data-label="Class 1-3">₹150</td>
                                <td data-label="Class 4-8">₹200</td>
                                <td data-label="Class 9">₹300</td>
                                <td data-label="Class 10">₹300</td>
                            </tr>
                            <tr>
                                <td data-label="Fee Type">Annual Exam</td>
                                <td data-label="LKG & UKG">₹150</td>
                                <td data-label="Class 1-3">₹150</td>
                                <td data-label="Class 4-8">₹200</td>
                                <td data-label="Class 9">₹300</td>
                                <td data-label="Class 10">₹300</td>
                            </tr>
                        </tbody>
                    </table>
                </section>

                <section>
                    <h2>Monthly Transportation Charges</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Distance</th>
                                <th>Charge</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td data-label="Distance">01 km to 03 km</td>
                                <td data-label="Charge">₹500</td>
                            </tr>
                            <tr>
                                <td data-label="Distance">04 km to 06 km</td>
                                <td data-label="Charge">₹600</td>
                            </tr>
                            <tr>
                                <td data-label="Distance">06 km to 08 km</td>
                                <td data-label="Charge">₹700</td>
                            </tr>
                            <tr>
                                <td data-label="Distance">08 km to 10 km</td>
                                <td data-label="Charge">₹900</td>
                            </tr>
                        </tbody>
                    </table>
                </section>

                <section>
                    <h2>Uniform Cost</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Cost</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td data-label="Item">Uniform (Size 24-28) with tie & belt</td>
                                <td data-label="Cost">₹650</td>
                            </tr>
                            <tr>
                                <td data-label="Item">Uniform (Size 36-40)</td>
                                <td data-label="Cost">₹1,000</td>
                            </tr>
                            <tr>
                                <td data-label="Item">Short belt</td>
                                <td data-label="Cost">₹50</td>
                            </tr>
                            <tr>
                                <td data-label="Item">Long belt</td>
                                <td data-label="Cost">₹80</td>
                            </tr>
                            <tr>
                                <td data-label="Item">Short tie</td>
                                <td data-label="Cost">₹50</td>
                            </tr>
                            <tr>
                                <td data-label="Item">Long tie</td>
                                <td data-label="Cost">₹100</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
            </div></>
    )
}
