import React, { useEffect, useState } from 'react';
import '../styles/Header.scss';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationPin} from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  const { t } = useTranslation();
  const [showLanguagePrompt, setShowLanguagePrompt] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(true)
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 650);
  useEffect(() => {
    const languageSet = localStorage.getItem('languageSet');
    setIsMobileView(window.innerWidth < 650);
    if (!languageSet) {
      setShowLanguagePrompt(true);
    }

    if (isMobileView) {
      setIsMenuOpen(false)
    }

  }, []);

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('languageSet', 'true');
    setShowLanguagePrompt(false);
  };

  // const showLanguagePrompt

  const LanguagePrompt = () => (
    <div className="language-prompt">
      <section><p>PLEASE CHOOSE YOUR LANGUAGE</p>
        <button onClick={() => handleLanguageChange('hi')}><img src="/icons/ind.png" alt="" /> हिंदी</button>
        <button onClick={() => handleLanguageChange('en')}> <img src="/icons/eng.png" alt="" /> English</button></section>
      {/* Add more language options as needed */}
    </div>
  );

  return (
    <header>
      {showLanguagePrompt && <LanguagePrompt />}
      <div className="top">
        <section className="call_us">
          <a href="tel:7654164726"><img src="https://static.vecteezy.com/system/resources/previews/009/384/296/original/call-us-buttons-clipart-design-illustration-free-png.png" alt="Call Parbhat Tara Vidya Niketan" /></a>
          <button onClick={() => setShowLanguagePrompt(true)}>
            <img src="/icons/translation.png" alt="" />
          </button>
        </section>
        <section className="social_media_links">
          <a href="https://www.google.com/search?q=prabhat+tara+vidya+niketan&sca_esv=ab65fc6f24c45a6f&sca_upv=1&rlz=1C1FKPE_enIN1099IN1099&sxsrf=ADLYWILLt4kf2DK87_EVo7FXRjco5E9ZBQ%3A1721989100112&ei=7HejZpy4Bqi00-kP7M2wgAg&ved=0ahUKEwic3vmkvcSHAxUo2jQHHewmDIAQ4dUDCBA&uact=5&oq=prabhat+tara+vidya+niketan&gs_lp=Egxnd3Mtd2l6LXNlcnAiGnByYWJoYXQgdGFyYSB2aWR5YSBuaWtldGFuMgQQABhHMgQQABhHMgQQABhHMgQQABhHMgQQABhHMgQQABhHMgQQABhHMgQQABhHSMgCUGJYYnAAeAKQAQCYAQCgAQCqAQC4AQPIAQD4AQGYAgGgAg6YAwCIBgGQBgiSBwExoAcA&sclient=gws-wiz-serp#lrd=0x39ed6b88a06921db:0x62a848a0ca5eb38b,1,,,,"><img src="https://png.pngtree.com/png-vector/20220521/ourmid/pngtree-review-us-feedback-rating-design-png-image_4668139.png" alt="" /></a>
          <a href="https://www.facebook.com/profile.php?id=61562571579177">
            <img src="https://cdn.icon-icons.com/icons2/1508/PNG/512/facebook_104354.png" alt="" />
          </a>
          <a href="https://www.instagram.com/prabhattaravidyaniketan/">
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/768px-Instagram_icon.png" alt="" />
          </a>
        </section>
      </div>

      <div className="center">
        <section className="logo_name">
          <img src="/images/logo.jpg" alt="Prabhat Tara Vidya Niketan Logo" />
          <span>
            <h1>{t('school_name')}</h1>
            <p> <FontAwesomeIcon icon={faLocationPin}/> {t('school_address')}</p>
            <p>Affiliated to CBSE (Upto 10 + 2)</p>
          </span>
        </section>
        <section className="years_of_service">
          <img src="/images/yrs-of-service.png" alt="Years of Service" />
        </section>
      </div>
      <div className="bottom">
        {isMobileView && (
          <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
            {isMenuOpen ? (<span>x</span>) : (<span>≡</span>)}
          </button>
        )}
        {isMenuOpen && (
          <nav>
            <section>
              <a href="/">{t('home')}</a>
            </section>
            <section>
              <p>{t('about_us')}</p>
              <div>
                <a href="#">{t('our_vision')}</a>
                <a href="#">{t('our_mission')}</a>
                <a href="#">{t('history')}</a>
                <a href="#">{t('principal_message')}</a>
                <a href="#">{t('director_message')}</a>
              </div>
            </section>
            <section>
              <p>{t('mandatory_disclosure')}</p>
              <div>
                <a href="/fee-structure">{t('fee_structure')}</a>
              </div>
            </section>
            <section>
              <a href="/teaching-staff">{t('teaching_staff')}</a>
            </section>
            <section>
              <a href="">{t('gallery')}</a>
            </section>
          </nav>
        )}
      </div>

    </header>
  );
};

export default Header;