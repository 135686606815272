import React from 'react'
import '../styles/Loader.scss'
export default function Loader({text="Reaching PTVN Soon.."}) {
  return (
    <div className='loader'>
        <img src="/icons/loading.gif" alt="" />
        <p>{text}</p>
    </div>
  )
}

