import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Loader from './components/Loader';
import Header from './components/Header'
// import PaintingStudent from './components/PaintingStudent';
import Hero from './components/Hero';
import TeachingStaff from './pages/TeachingStaff';
import FeeStructure from './pages/FeeStructure';
import Footer from './components/Footer';
function App() {
  const [isClientLoaded, setIsClientLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsClientLoaded(true);
    }, 3000);
  }, []);

  if (!isClientLoaded) {
    return <Loader />;
  }

  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/teaching-staff" element={<TeachingStaff />} />
          <Route path='/fee-structure' element={<FeeStructure/>}/>
          {/* You can add more routes as needed */}
        </Routes>
        {/* <PaintingStudent/> */}
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
